import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { httpAdmin } from "../../Apis/commonApis";
import { Controller, useForm } from "react-hook-form";
import { addCarePlanTemplate } from "../../redux-toolkit/reducer/carePlanReducer";
import { useDispatch, useSelector } from "react-redux";
import { compressImage, getChangedKeysVals } from "../../utils/CommonFunctions";

const chatUserImg = "/images/chatUser.svg";

const AboutMe = (props) => {
  const { clientAllData, Profileaccess, clientId, getClientbyId } = props;
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const [editClientProfileData, setEditClientProfileData] = useState([]);
  const [changeBtn, setchangeBtn] = useState(true);

  useEffect(() => {
    if (clientAllData) {
      setClientDataFunc(clientAllData);
    }
  }, [clientAllData]);

  const editClient = async (postData) => {
    let formdata = { ...postData };
    let editData = getChangedKeysVals(editClientProfileData, formdata);
    setchangeBtn(true);
    if (Object.keys(editData)?.length < 1) {
      setchangeBtn(true);
      return;
    }
    const res = await httpAdmin(
      `edit_client/${clientId}`,
      "post",
      editData,
      null
    );
    if (res.status) {
      setClientDataFunc(res.data);
      getClientbyId();
      setchangeBtn(true);
    }
    swal(
      res.status ? "Success" : "Failed",
      res.message,
      res.status ? "success" : "error"
    );
  };

  function setClientDataFunc(ClientData) {
    setEditClientProfileData(ClientData);
    dispatch(addCarePlanTemplate({ firstName: ClientData?.first_Name }));
  }

  return (
    <div style={{ height: "80vh", overflowY: "auto" }}>
      <div id="aboutMePage" className="row">
        <div className="col-md-6 pe-3">
          <div className="card_inbox">
            <div className="view_details position-relative">
              <div className="hideWhilePrint-clientProfile">
                <img
                  src={editClientProfileData?.image ?? chatUserImg}
                  className="profile_img"
                  alt="user"
                />
                <div className="float-end btns_head mb-3">
                  {changeBtn ? (
                    <button
                      className="btn btn-theme btn-sm"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setchangeBtn(false);
                      }}
                      disabled={Profileaccess !== "full"}
                    >
                      Edit
                    </button>
                  ) : (
                    <button
                      className="btn btn-theme btn-sm"
                      type="submit"
                      onClick={handleSubmit(editClient)}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
              <table className="table table-striped table_half">
                <tbody>
                  <tr>
                    <td>First Name</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.first_Name}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          style={{ minWidth: "24vw" }}
                          defaultValue={editClientProfileData?.first_Name}
                          className="form-control form-control-sm"
                          {...register("first_Name")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Last Name</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.last_Name}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.last_Name}
                          className="form-control form-control-sm"
                          {...register("last_Name")}
                        />
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>What Is Most Important To Me</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.whatIsMostImportantToMe || "--"}
                      </td>
                    ) : (
                      <td>
                        <textarea
                          type="text"
                          rows={4}
                          defaultValue={
                            editClientProfileData?.whatIsMostImportantToMe
                          }
                          title="A description of what is most important to you"
                          placeholder="A description of what is most important to you"
                          className="form-control form-control-sm"
                          {...register("whatIsMostImportantToMe")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>People Who Are Important To Me</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.peopleWhoAreImportantToMe ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <textarea
                          type="text"
                          rows={4}
                          defaultValue={
                            editClientProfileData?.peopleWhoAreImportantToMe
                          }
                          className="form-control form-control-sm"
                          title="Details of who is important to you and why"
                          placeholder="Details of who is important to you and why"
                          {...register("peopleWhoAreImportantToMe")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Care Workers</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.careWorkerAboutMe || "--"}
                      </td>
                    ) : (
                      <td>
                        <textarea
                          type="text"
                          rows={4}
                          defaultValue={
                            editClientProfileData?.careWorkerAboutMe
                          }
                          className="form-control form-control-sm"
                          {...register("careWorkerAboutMe")}
                        />
                      </td>
                    )}
                  </tr>
                  {/* <tr>
                    <td>Subject of Care</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.subjectOfCareAboutMe || '--'}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.subjectOfCareAboutMe}
                          // onChange={(e) => setlastName(e.target.value)}
                          className="form-control form-control-sm"
                          {...register("subjectOfCareAboutMe")}
                        />
                      </td>
                    )}
                  </tr> */}

                  <tr>
                    <td>How I Communicate And How To Communicate With Me</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.howICommunicateAndHowToCommunicateWithMe ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <textarea
                          type="text"
                          rows={4}
                          defaultValue={
                            editClientProfileData?.howICommunicateAndHowToCommunicateWithMe
                          }
                          // onChange={(e) => setlastName(e.target.value)}
                          className="form-control form-control-sm"
                          title="A description of how you communicate normally"
                          placeholder="A description of how you communicate normally"
                          {...register(
                            "howICommunicateAndHowToCommunicateWithMe"
                          )}
                        />
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="col-md-6 ps-3">
          <div className="mb-4 card_inbox">
            <div className="view_details">
              {/* <h5 className="tb_title">Address</h5> */}
              <table className="table table-striped table_half">
                <tbody>
                  <tr>
                    <td>My Wellness</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.myWellness || "--"}</td>
                    ) : (
                      <td>
                        <textarea
                          type="text"
                          rows={4}
                          style={{ minWidth: "25vw" }}
                          defaultValue={editClientProfileData?.myWellness}
                          // onChange={(e) => setlastName(e.target.value)}
                          className="form-control form-control-sm"
                          title="A description covering what you are able to do, how you engage with others and how you feel on a typical day through to on a day when you are unwell or really unwell"
                          placeholder="A description covering what you are able to do, how you engage with others and how you feel on a typical day through to on a day when you are unwell or really unwell"
                          {...register("myWellness")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Please Do</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.pleaseDo || "--"}</td>
                    ) : (
                      <td>
                        <textarea
                          type="text"
                          rows={4}
                          defaultValue={editClientProfileData?.pleaseDo}
                          // onChange={(e) => setlastName(e.target.value)}
                          className="form-control form-control-sm"
                          title="A description of things you want someone supporting you to do"
                          placeholder="A description of things you want someone supporting you to do"
                          {...register("pleaseDo")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Please Don't</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.pleaseDont || "--"}</td>
                    ) : (
                      <td>
                        <textarea
                          type="text"
                          rows={4}
                          defaultValue={editClientProfileData?.pleaseDont}
                          // onChange={(e) => setlastName(e.target.value)}
                          className="form-control form-control-sm"
                          title="A description of things you want someone supporting you not to do"
                          placeholder="A description of things you want someone supporting you not to do"
                          {...register("pleaseDont")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>How and when to support me</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.howAndWhenSupportMe || "--"}
                      </td>
                    ) : (
                      <td>
                        <textarea
                          type="text"
                          rows={4}
                          defaultValue={
                            editClientProfileData?.howAndWhenSupportMe
                          }
                          // onChange={(e) => setlastName(e.target.value)}
                          className="form-control form-control-sm"
                          title="A description of how and when you want someone caring for you to support you"
                          placeholder="A description of how and when you want someone caring for you to support you"
                          {...register("howAndWhenSupportMe")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Also Worth Knowing About Me</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.alsoWorthKnowingAboutMe || "--"}
                      </td>
                    ) : (
                      <td>
                        <textarea
                          type="text"
                          rows={4}
                          defaultValue={
                            editClientProfileData?.alsoWorthKnowingAboutMe
                          }
                          // onChange={(e) => setlastName(e.target.value)}
                          className="form-control form-control-sm"
                          title="A description of what is also worth knowing about you for people caring or supporting you"
                          placeholder="A description of what is also worth knowing about you for people caring or supporting you"
                          {...register("alsoWorthKnowingAboutMe")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Supported to write this by</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.supportedByWriteThisBy || "--"}
                      </td>
                    ) : (
                      <td>
                        <textarea
                          type="text"
                          rows={4}
                          defaultValue={
                            editClientProfileData?.supportedByWriteThisBy
                          }
                          // onChange={(e) => setlastName(e.target.value)}
                          className="form-control form-control-sm"
                          title="Where relevant, this is a record of name, relationship/role and contact details of the individual who supported the person to write this section e.g. carer, family member, advocate, professional"
                          placeholder="Where relevant, this is a record of name, relationship/role and contact details of the individual who supported the person to write this section e.g. carer, family member, advocate, professional"
                          {...register("supportedByWriteThisBy")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Location To Multimedia File</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.locationToMultimediaFile ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <textarea
                          type="text"
                          rows={4}
                          defaultValue={
                            editClientProfileData?.locationToMultimediaFile
                          }
                          // onChange={(e) => setlastName(e.target.value)}
                          className="form-control form-control-sm"
                          title="The link to the person's multimedia file"
                          placeholder="The link to the person's multimedia file"
                          {...register("locationToMultimediaFile")}
                        />
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>Date Regarding About Me</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.dateRegardingAboutMe
                          ? editClientProfileData?.dateRegardingAboutMe?.split(
                              "T"
                            )?.[0]
                          : "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="date"
                          defaultValue={
                            editClientProfileData?.dateRegardingAboutMe
                          }
                          className="form-control form-control-sm"
                          {...register("dateRegardingAboutMe")}
                        />
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const columns = [
  { field: "name", headerName: "Name", width: 90 },
  {
    field: "type",
    headerName: "Type",
    width: 120,
    editable: true,
  },
  {
    field: "start_Date",
    headerName: "Start Date",
    width: 120,
    editable: true,
  },
  { field: "diagnosedBy", headerName: "Diagnosed By", width: 120 },
];

export default AboutMe;
