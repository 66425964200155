import React, { useEffect, useState } from "react";
import "../Clients/clients.css";
import AdminWrapper from "../../components/AdminWrapper";
import { useDispatch, useSelector } from "react-redux";
import { addCarePlanTemplate } from "../../redux-toolkit/reducer/carePlanReducer";
import ClientRoutes from "./clientRoute/ClientRoutes";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Menu, MenuItem, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { exportToExcel } from "react-json-to-excel";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
// import { ShowInfoSwal } from "../../utils/alertSwal";

function ClientsDetails() {
  const history = useHistory();
  const location = useLocation();
  const clientId = location.state?.clientId;
  let { path, url } = useRouteMatch();

  const dispatch = useDispatch();
  const { adminClients } = useSelector((state) => state.adminClientReducer);

  const handleNavigate = (type) => {
    // if (currentPlanName !== 'Turbo' && type === 'docs') {
    //   ShowInfoSwal(`This feature is not available in the ${currentPlanName} Plan`);
    //   return;
    // }
    dispatch(
      addCarePlanTemplate({
        savedPlanHistoryStatus: false,
      })
    );
    history.replace({
      pathname: `${url}/${type}`,
      state: { clientId: clientId },
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [downloadingPages, setDownloadingPages] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentUrl = location.pathname;

  const handleMenuClick = (type) => {
    // console.log(adminClients, "aaaaaaaaccccccccc");
    handleClose();
    if (type === "excel") {
      const dataToExport = mapDataToExport(adminClients, currentUrl);
      // console.log(dataToExport, "dataToExport");
      exportToExcel(
        dataToExport.data,
        `${adminClients.first_Name}-${dataToExport.fileName}-data`
      );
    } else {
      activatePagesBeforDownloading();
    }
  };

  const isHideHamsburg =
    currentUrl.includes("docs") ||
    currentUrl.includes("care-plan") ||
    currentUrl.includes("journal");
  const hamsburgHideStyle = isHideHamsburg
    ? { opacity: 0, cursor: "initial !important" }
    : {};

  useEffect(() => {
    if (downloadingPages) {
      saveToDownloads();
    }
  }, [downloadingPages]);

  const activatePagesBeforDownloading = () => {
    window.scrollTo(0, 0);
    setDownloadingPages(true);
  };

  const saveToDownloads = async () => {
    let downloadPageName = "";

    if (currentUrl.includes("profile")) {
      downloadPageName = "profile";
    } else if (currentUrl.includes("about-me")) {
      downloadPageName = "aboutMe";
    } else if (currentUrl.includes("admin-details")) {
      downloadPageName = "adminDetails";
    } else {
      return;
    }

    const fileName = `${adminClients.first_Name}-${downloadPageName}-data`;
    // const input = document.getElementById(`client${downloadPageName}Page`);

    var pdf = new jsPDF("p", "in", [8.5, 11]);
    const componentIds = [
      "clientprofilePage",
      "aboutMePage",
      "adminDetailsPage",
    ];

    for (let i = 0; i < componentIds.length; i++) {
      const input = document.getElementById(componentIds[i]);
      if (input) {
        const hiddenHeaders = input.getElementsByClassName(
          "hideWhilePrint-clientProfile"
        );
        for (const header of hiddenHeaders) {
          header.style.display = "none";
        }
        const canvas = await html2canvas(input, {
          proxy: adminClients.image,
          scrollY: 0,
        });

        for (const header of hiddenHeaders) {
          header.style.display = "block";
        }

        const image = { type: "jpeg", quality: 0.99 };
        const margin = [0.6, 0.5];

        const imgWidth = 8.5;
        let pageHeight = 11;

        const innerPageWidth = imgWidth - margin[0] * 2;
        const innerPageHeight = pageHeight - margin[1] * 2;

        // Calculate the number of pages.
        const pxFullHeight = canvas.height;
        const pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
        const nPages = Math.ceil(pxFullHeight / pxPageHeight);

        // Define pageHeight separately so it can be trimmed on the final page.
        pageHeight = innerPageHeight;

        // Create a one-page canvas to split up the full image.
        const pageCanvas = document.createElement("canvas");
        const pageCtx = pageCanvas.getContext("2d");
        pageCanvas.width = canvas.width;
        pageCanvas.height = pxPageHeight;

        // Initialize the PDF.

        for (let page = 0; page < nPages; page++) {
          // Trim the final page to reduce file size.
          if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
            pageCanvas.height = pxFullHeight % pxPageHeight;
            pageHeight =
              (pageCanvas.height * innerPageWidth) / pageCanvas.width;
          }

          // Display the page.
          const w = pageCanvas.width;
          const h = pageCanvas.height;
          pageCtx.fillStyle = "white";
          pageCtx.fillRect(0, 0, w, h);
          pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

          // Add the page to the PDF.
          if (page > 0 || i > 0) pdf.addPage();
          // debugger;
          const imgData = pageCanvas.toDataURL(
            "image/" + image.type,
            image.quality
          );
          pdf.addImage(
            imgData,
            image.type,
            margin[1],
            margin[0],
            innerPageWidth,
            pageHeight
          );

          // // Footer: Grey border line
          // pdf.setDrawColor(180); // Grey color
          // pdf.line(10, 285, 200, 285); // Line above footer

          // // Footer: Page number (right-aligned)
          // pdf.setFontSize(10);
          // pdf.text(`Page ${i + 1} of ${'3'}`, 180, 290); // Right side
        }
      }
    }
    pdf.save(fileName);
    setDownloadingPages(false);
  };

  return (
    <>
      <AdminWrapper>
        <div className="card">
          <div className="card-body">
            <div className="top_menubar">
              <ul
                className="nav nav-tabs ract_tab_list border-0 listspaceGap align-items-center"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    // disabled={currentModuleAccess?.Profile === "no"}
                    onClick={() => handleNavigate("profile")}
                    className={`nav-link curser_point ${
                      window.location.href.includes("profile") ? "active" : ""
                    }`}
                  >
                    Profile
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    // disabled={currentModuleAccess?.Profile === "no"}
                    onClick={() => handleNavigate("about-me")}
                    className={`nav-link curser_point ${
                      window.location.href.includes("about-me") ? "active" : ""
                    }`}
                  >
                    About Me
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    // disabled={currentModuleAccess?.Profile === "no"}
                    onClick={() => handleNavigate("admin-details")}
                    className={`nav-link curser_point ${
                      window.location.href.includes("admin-details")
                        ? "active"
                        : ""
                    }`}
                  >
                    Admin
                  </button>
                </li>
                <li className={`nav-item`} role="presentation">
                  <button
                    // disabled={currentModuleAccess?.Contacts === "no"}
                    onClick={() => handleNavigate("contacts")}
                    className={`nav-link curser_point ${
                      window.location.href.includes("contacts") ? "active" : ""
                    }`}
                  >
                    Contacts
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    // disabled={currentModuleAccess?.['Compliance Docs'] === "no"}
                    onClick={() => handleNavigate("docs")}
                    className={`nav-link curser_point ${
                      window.location.href.includes("docs") ? "active" : ""
                    }`}
                  >
                    Docs
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    // disabled={currentModuleAccess?.Journal === "no"}
                    onClick={() => handleNavigate("journal")}
                    className={`nav-link curser_point ${
                      window.location.href.includes("journal") ? "active" : ""
                    }`}
                  >
                    Journal
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    // disabled={currentModuleAccess?.['Care Plan'] === "no"}
                    className={`nav-link curser_point ${
                      window.location.href.includes("care-plan") ? "active" : ""
                    }`}
                    onClick={() => {
                      handleNavigate("care-plan");
                    }}
                  >
                    Care Plan
                  </button>
                </li>

                <li className="nav-item position-relative" role="presentation">
                  <IconButton
                    disabled={isHideHamsburg}
                    onClick={handleClick}
                    style={hamsburgHideStyle}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        // boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                        border: "0.6px solid rgb(121 120 120 / 10%)",
                        borderRadius: "8px",
                        minWidth: "150px",
                      },
                    }}
                  >
                    <MenuItem onClick={() => handleMenuClick("excel")}>
                      Download Excel
                    </MenuItem>
                    {!currentUrl?.includes("contacts") &&
                      !currentUrl?.includes("journal") && (
                        <MenuItem onClick={() => handleMenuClick("pdf")}>
                          Download Pdf
                        </MenuItem>
                      )}
                  </Menu>
                </li>
              </ul>
            </div>
            <div className="tab-content mt-3" id="myTabContent">
              <Switch>
                <Route path={`${path}/:topicId`}>
                  <ClientRoutes downloadingPages={downloadingPages} />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </AdminWrapper>
    </>
  );
}

export default ClientsDetails;

function mapDataToExport(data, url) {
  if (url.includes("profile")) {
    return {
      fileName: "profile",
      data: [
        {
          "First Name": data?.first_Name ?? "",
          "Last Name": data?.last_Name ?? "",
          "Prefered Name": data?.preferredName ?? "",
          Pronoun: data?.pronoun ?? "",
          "Date of Birth": data?.DOB ?? "",
          "NHS Number": data?.NHS_number ?? "",
          Gender: data?.gender ?? "",
          "Sexual Orientation": data?.sexualOrientation ?? "",
          "Contract Start Date": data?.contractStartdate ?? "",
          "Contract End Date": data?.contractEnddate ?? "",
          "Blood Group": data?.bloodGroup ?? "",
          Profession: data?.profession ?? "",
          "Care Provider": data?.careProvider ?? "",
          "Marital Status": data?.marital_Status ?? "",
          Religion: data?.religion ?? "",
          Ethnicity: data?.ethnicity ?? "",
          Citizenship: data?.citizenship ?? "",
          "First Language": data?.firstLanguage ?? "",
          "Other Language": data?.otherLanguage ?? "",
          "Immigration Status": data?.immigrationStatus ?? "",
          "Interpreter Required": data?.interpreterRequired ?? "",
          "Pregnancy Status": data?.pregnancyStatus ?? "",
          Height: data?.height ?? "",
          Weight: data?.weight ?? "",
          "Eye Color": data?.eye_Color ?? "",
          "Hair Color": data?.hair_Color ?? "",
          "Distinguishing Mark": data?.distinguishing_Mark ?? "",
          "Room No": data?.roomNo ?? "",
          "Current Address (Location)": data?.location ?? "",
          UPRN: data?.systemUPRN ?? "",
          "Address association type": data?.addressAssociationType ?? "",
          "Address association start date":
            data?.addressAssociationStartDate ?? "",
          "Address association end date": data?.addressAssociationEndDate ?? "",
          "Permanent Address": data?.permanentAddress ?? "",
          "Currently Resident": data?.currentlyResidence ?? "",
        },
      ],
    };
  } else if (url.includes("about-me")) {
    return {
      fileName: "about-me",
      data: [
        {
          "First Name": data?.first_Name ?? "",
          "Last Name": data?.last_Name ?? "",
          "What Is Most Important To Me": data?.whatIsMostImportantToMe ?? "",
          "People Who Are Important To Me":
            data?.peopleWhoAreImportantToMe ?? "",
          "Care Workers": data?.careWorkerAboutMe ?? "",
          "How I Communicate And How To Communicate With Me":
            data?.howICommunicateAndHowToCommunicateWithMe ?? "",
          "My Wellness": data?.myWellness ?? "",
          "Please Do": data?.pleaseDo ?? "",
          "Please Don't": data?.pleaseDont ?? "",
          "How and when to support me": data?.howAndWhenSupportMe ?? "",
          "Also Worth Knowing About Me": data?.alsoWorthKnowingAboutMe ?? "",
          "Supported to write this by": data?.supportedByWriteThisBy ?? "",
          "Location To Multimedia File": data?.locationToMultimediaFile ?? "",
          "Date Regarding About Me": data?.dateRegardingAboutMe ?? "",
        },
      ],
    };
  } else if (url.includes("admin-details")) {
    return {
      fileName: "admin-details",
      data: [
        {
          "Mental Capacity to Consent": data?.consentMentalCapacity ?? "",
          "Power of Attorney in Place": data?.consentPOA ?? "",
          "Authorized Person": data?.authrisedPerson ?? "",
          "Advanced Directive": data?.advance_Directive ?? "",
          "Advance decision to refuse treatment preference":
            data?.advanceDecisioToRefuseTreatmentPreference ?? "",
          "Location of document (ADRT)":
            data?.locationOfDocumentForConsent ?? "",
          "Advance statement": data?.advanceStatementFreeText ?? "",
          "Advance statement Location":
            data?.advanceStatementLocationOfDocument ?? "",
          "Care Actor (CPR)": data?.careActor ?? "",
          "CPR decision": data?.CPR ?? "",
          "CPR Date": data?.CPRDate ?? "",
          "CPR Informed": data?.CPRInformed ?? "",
          Location: data?.CPRLocation ?? "",
          "Location of document": data?.CPRLocationOfDocument ?? "",
          "Review of CPR decision": data?.CPRReviewOfCPR ?? "",
          "Subject of care (CPR)": data?.CPRSubjectOfCare ?? "",
          Time: data?.CPRTime ?? "",
          "Application date": data?.DOLSApplicationDate ?? "",
          "Application status": data?.DOLSApplicationStatus ?? "",
          Location: data?.DOLSLocation ?? "",
          "Location of document": data?.DOLSLocationOfDocument ?? "",
          "Planned or actual end date of authorisation":
            data?.DOLSActualOrPlanned ?? "",
          "Reason for rejection": data?.DOLSReasonForRejection ?? "",
          "DoLS Review": data?.DOLSReview ?? "",
          "Start date of authorisation":
            data?.DOLSStartDateOFAuthrisation ?? "",
          "Care worker": data?.informedConsentCareWorker ?? "",
          Caveat: data?.informedConsentCaveat ?? "",
          "Consent description": data?.informedConsentDescription ?? "",
          "Informed Consent End date": data?.informedConsentEndDate ?? "",
          Focus: data?.informedConsentFocus ?? "",
          "Form of consent": data?.informedConsentFormOfConsent ?? "",
          "Informed Consent Activity Location":
            data?.informedConsentLocation ?? "",
          "Location of document": data?.informedConsentLocationOfDocument ?? "",
          "Proxy representation":
            data?.informedConsentProxyRepresentation ?? "",
          "Reason consent not given":
            data?.informedConsentReasonConsentNotGiven ?? "",
          "Informed consent Start date": data?.informedConsentStartDate ?? "",
          "The informed consent status": data?.informedConsentStatus ?? "",
          "Subject of care": data?.informedConsentSubjectOfCare ?? "",
          Attorney: data?.LPA_attroney ?? "",
          "Location of document (LPA)": data?.LPA_LocationOfDocument ?? "",
          "Subject of care proxy (LPA)": data?.LPA_SubjectOfCareProxy ?? "",
          "Location (MHAE)": data?.mentalHealthLocation ?? "",
          "Location of document": data?.mentalHealthLocationOfDocument ?? "",
          "Section start date": data?.mentalHealthStartDate ?? "",
          "Section end date": data?.mentalHealthEndDate ?? "",
          "Mental Health Act": data?.mentalHealthCodedvalue ?? "",
          "Supporting information":
            data?.mentalHealthSupportingInformation ?? "",
          UPRN: data?.UPRN ?? "",
          "Address entry 1": data?.admissionAddressEntry1 ?? "",
          "Address entry 2": data?.admissionAddressEntry2 ?? "",
          "Address entry 3": data?.admissionAddressEntry3 ?? "",
          "Address entry 4": data?.admissionAddressEntry4 ?? "",
          "Address entry 5": data?.admissionAddressEntry5 ?? "",
          Country: data?.admissionCountry ?? "",
          Postcode: data?.admissionPostcode ?? "",
          "Admitted to": data?.admissionAdmittedTo ?? "",
          Date: data?.dateAdmissionDetails ?? "",
          "Individual accompanying person":
            data?.admissionIndividualAccompanyingPerson ?? "",
          "Legal status on admission": data?.legelStatusOnAdmission ?? "",
          "Responsible care worker":
            data?.responsibleCareWorkerForAdmission ?? "",
          "Admission method": data?.admisssionMethod ?? "",
          "Reason for admission": data?.reasonForAdmission ?? "",
          "Source of admission": data?.sourceOfAdmission ?? "",
          Specialty: data?.specialityOfAdmission ?? "",
          Date: data?.dateRegardingDischarge ?? "",
          "Discharged from": data?.dischargedFrom ?? "",
          "Discharging care worker": data?.dischargingCareWorker ?? "",
          "Discharging department": data?.dischargingDepartment ?? "",
          "Legal status on discharge": data?.legalStatusOnDischarge ?? "",
          "Discharge address": data?.dischargeAddress ?? "",
          "Discharge destination": data?.dischargeDestination ?? "",
          "Discharge Method": data?.dischargeMethod ?? "",
          "Discharging specialty": data?.dischargeSpeciality ?? "",
          "Death cause identification method":
            data?.deathCauseIdentificationMethod ?? "",
          "Death location type code": data?.deathLocationTypeCode ?? "",
          "Death not at preferred location reason":
            data?.deathNotAtPreferredLocationReason ?? "",
          "person at risk of unexpected death indicator":
            data?.personAtRiskOfUnexpectedDeathIndicator ?? "",
          "Person death date": data?.personDeathDate ?? "",
          "Person death Time": data?.personDeathTime ?? "",
          "Preferred death location discussed indicator":
            data?.preferredDeathLocationDiscussedIndicator ?? "",
        },
      ],
    };
  } else if (url.includes("contacts")) {
    return {
      fileName: "contacts",
      data: data.keycontact.map((elem) => {
        return {
          Name: elem.name,
          "Contact Type": elem.contactType ?? "",
          Relationship: elem.relationship ?? "",
          "Relationship Description": elem.relationshipDescription ?? "",
          "Household Member": elem.houseHoldMember ?? "",
          Address: elem.address ?? "",
          Telephone: elem.phone ?? "",
          "Email Address": elem.email ?? "",
          "Consent Authority": elem.consentAuthority ?? "",
          "Friends&Family App": elem.isFandFappActive ? "Active" : "Inactive",
        };
      }),
    };
  } else if (url.includes("journal")) {
    return {
      fileName: "journal",
      data: {},
    };
  }
}
